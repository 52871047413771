import * as React from "react";
import { Github, Linkedin, Twitter } from "../../utils/icons";

import "./index.css";

const Header: React.SFC = () => (
  <div className="header">
    <div className="header--navbar">
      <div className="header--navbar-item">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://github.com/jaimevelaz"
          aria-label="Github"
        >
          <Github />
        </a>
      </div>
      <div className="header--navbar-item">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://twitter.com/jaimevelaz"
          aria-label="Twitter"
        >
          <Twitter />
        </a>
      </div>
      <div className="header--navbar-item">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.linkedin.com/in/jaimevelaz"
          aria-label="Linkedin"
        >
          <Linkedin />
        </a>
      </div>
    </div>
  </div>
);

export default Header;
