import { graphql } from "gatsby";
import React from "react";
import Avatar from "../components/Avatar";
import Layout from "../components/Layout";

import "./index.css";

interface IndexPageProps {
  data: {
    site: {
      siteMetadata: {
        title: string;
        job: string;
      };
    };
  };
}

export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
        job
      }
    }
  }
`;

export default class IndexPage extends React.Component<IndexPageProps, {}> {
  public render() {
    const { title, job } = this.props.data.site.siteMetadata;
    return (
      <Layout>
        <div className="content">
          <Avatar name={title} job={job} />
          <hr />
          <div className="page--section">
            <h4>Current project</h4>
            <p>
              I'm the tech lead on the shopper experience team at{" "}
              <a
                className="sequra-color"
                target="_blank"
                rel="noopener noreferrer"
                href="https://sequra.es"
              >
                SeQura
              </a>{" "}
              which focus in giving a good and consistent end to end user
              experience.
            </p>
          </div>
          <div className="page--section">
            <h4>Tech stack</h4>
            <p>
              I feel confident in Ruby and Elixir ecosystems, also I have spent
              time building frontend interfaces with React. Said that, I am
              always looking for new technologies or paradigms which help me
              improve as a software developer, currently I am focusing in Elixir
              and improving as tech lead.
            </p>
          </div>
        </div>
      </Layout>
    );
  }
}
