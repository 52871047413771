import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import "./index.css";

interface AvatarProps {
  name: string;
  job: string;
  image: string;
}

const Avatar: React.SFC<AvatarProps> = ({ name, job, image }) => (
  <div className="avatar">
    <StaticImage
      src="../../assets/images/jaime.png"
      className="avatar--image"
      alt="handsome developer smiling"
    />
    <h1>{name}</h1>
    <h2>
      {job}
      <span className="sequra-color">sequra</span>
    </h2>
  </div>
);

export default Avatar;
