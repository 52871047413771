import * as React from "react"
import Helmet from "react-helmet"
import androidIcon192_192 from "../../assets/images/android-icon-192x192.png"
import favicon16_16 from "../../assets/images/favicon-16x16.png"
import favicon32_32 from "../../assets/images/favicon-32x32.png"
import favicon96_96 from "../../assets/images/favicon-96x96.png"
import Header from "../Header"
import "./index.css"

const IndexLayout: React.SFC = ({ children }) => (
  <div className="page">
    <Helmet
      title="Jaime Velaz Dominguez"
      charSet="utf-8"
      htmlAttributes={{
        lang: "en"
      }}
      meta={[
        { name: "description", content: "Jaime Velaz Dominguez site" },
        {
          content: "jaime velaz, sequra, ruby, software engineer",
          name: "keywords"
        }
      ]}
      link={[
        {
          href: androidIcon192_192,
          rel: "icon",
          sizes: "192x192",
          type: "image/png"
        },
        {
          href: favicon16_16,
          rel: "icon",
          sizes: "32x32",
          type: "image/png"
        },
        {
          href: favicon32_32,
          rel: "icon",
          sizes: "96x96",
          type: "image/png"
        },
        {
          href: favicon96_96,
          rel: "icon",
          sizes: "16x16",
          type: "image/png"
        }
      ]}
    />
    <Header />
    {children}
    <div />
  </div>
)

export default IndexLayout
